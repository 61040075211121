import React from 'react';
import { Container, Row, Col } from 'reactstrap';

import { Layout } from '../Components';

const NotFoundPage = () => (
  <Layout>
    <Container>
      <Row>
        <Col md="12" style={{ minHeight: '60vh' }}>
          <div className="Header__text pr-5 pl-3 pt-3">
            <h1 className="text-center Page404">404</h1>
            <h1 className="text-center">NON TROUVÉE</h1>
            <p className="text-center">Vous tentez d’accéder à une page qui n’existe pas.</p>
          </div>
        </Col>
      </Row>
    </Container>
  </Layout>
);

export default NotFoundPage;
